// React 18
// 代码示例：https://github.com/Authing/Guard/blob/master/examples/guard-react18/modal/src/pages/Embed.tsx
import { useGuard, User } from '@authing/guard-react18';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginButton from '../../assets/button.svg';
import styles from './index.module.css';

export default function Embed() {
  const guard = useGuard();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState<User | null>(null);

  const showGuard = () => guard.show();

  useEffect(() => {
    // 挂载模态框，当用户完成登录之后，你可以获取到用户信息
    guard.start('#authing-guard-container').then((userInfo: User) => {
      console.log('userInfo: ', userInfo);
      setUserInfo(userInfo);

      setTimeout(() => {
        guard.hide();
        navigate('/home');
      }, 1000);
    });
  }, [navigate]);

  useEffect(() => {
    if (userInfo?.username) {
      console.log(`Welcome,${userInfo?.username}!`);
    }
  }, [userInfo?.username]);

  return (
    <div>
      {
        <button className={`${styles.btn} authing-button`} onClick={showGuard}>
          <img src={LoginButton} alt="LoginButton" />
        </button>
      }
    </div>
  );
}
