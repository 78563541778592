import { Outlet } from 'react-router-dom';
import LoginGuard from '@/components/login/loginGuard';

export default function Layout() {
  return (
    <LoginGuard>
      <Outlet />
      <div id="authing-guard-container"></div>
    </LoginGuard>
  );
}
