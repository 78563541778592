import React, { useEffect, useState } from 'react';
import { AuthenticationClient } from 'authing-js-sdk';
import { User } from '@authing/react18-ui-components';
import { loginByAuthCode } from '@/apis/third-login';

export const useLogin = (authClient: AuthenticationClient) => {
  const [user, setUser] = React.useState<User>();

  const [isLogin, setIsLogin] = useState(false);

  const checkLoginStatus = React.useCallback(async () => {
    // authClient 登录成功时 会将用户相关信息存储在 localStorage 中
    const user = await authClient.getCurrentUser();
    console.log('user = ', user);

    if (!user) {
      setIsLogin(false);
      return;
    }

    const token = user.token;

    if (!token) return;

    // 检查当前用户是否有登录态
    const { status } = await authClient.checkLoginStatus(token);

    if (status) {
      setUser(user);
      setIsLogin(true);
    }
  }, []);

  const loginByCode = React.useCallback(async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('authCode');

    if (code) {
      const data = await loginByAuthCode(code);
      console.log('data = ', data);
      const { id_token } = data.data.data.data;
      localStorage.setItem('_authing_token', id_token);
    }

    return;
  }, []);

  useEffect(() => {
    loginByCode().then(() => checkLoginStatus().then(console.log));
  }, [checkLoginStatus]);

  return isLogin;
};
