import * as THREE from 'three';
import { useTexture } from '@react-three/drei';
import { LayerMaterial, Depth, Noise } from 'lamina';
import { useXR } from '@react-three/xr';

const BG = () => {
  const { isPresenting } = useXR();

  const colorMap = useTexture('/assets/environment/1920-sunset-on-sky.jpg');

  if (isPresenting) return null;

  // THREE.MeshPhongMaterial()
  return (
    <mesh scale={100}>
      <sphereGeometry args={[5, 64, 64]} />
      <meshBasicMaterial map={colorMap} side={THREE.BackSide} />
      {/* <LayerMaterial side={THREE.BackSide}>
        <Depth
          colorA="skyblue"
          colorB="blue"
          alpha={0.7}
          mode="multiply"
          near={350}
          far={700}
          origin={[100, 100, -100]}
        />
        <Noise
          mapping="local"
          type="white"
          scale={1000}
          colorA="white"
          colorB="black"
          mode="subtract"
          alpha={0.2}
        />
      </LayerMaterial> */}
    </mesh>
  );
};

export default BG;
