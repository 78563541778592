import { Vector3 } from 'three';
export const randomNegativePositive = (range = 99) => {
  return (
    Math.ceil(Math.random() * range) * (Math.round(Math.random()) ? 1 : -1)
  );
};

export const mapGeoCoordtoLocalVector3 = (coord: GeolocationCoordinates) => {
  if (!coord) return;
  const COORD_TO_KM = 111;
  const KM_TO_VECTOR_MULTITUDE = 100;

  return new Vector3(
    (coord.latitude % (1 / KM_TO_VECTOR_MULTITUDE)) *
      COORD_TO_KM *
      KM_TO_VECTOR_MULTITUDE,
    0,
    (coord.longitude % (1 / KM_TO_VECTOR_MULTITUDE)) *
      COORD_TO_KM *
      KM_TO_VECTOR_MULTITUDE,
  );
};

export const calculateVelocity = (destination: Vector3, start: Vector3) => {
  const distance = destination.clone().sub(start).length();
  const velocity = destination.clone().sub(start).normalize();

  return { distance, velocity };
};

export const isDistanceWithinRange = (distance, range = 0.08): boolean => {
  return distance < range;
};

export const randomVector = (r) => [
  r / 2 - Math.random() * r,
  r / 2 - Math.random() * r,
  r / 2 - Math.random() * r,
];

export const randomEuler = () => [
  Math.random() * Math.PI,
  Math.random() * Math.PI,
  Math.random() * Math.PI,
];
