import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useLayoutEffect,
} from 'react';
import { Vector3, Mesh, Euler } from 'three';
import { useFrame, Color } from '@react-three/fiber';
import { useFBX, useAnimations } from '@react-three/drei';
import { useXR } from '@react-three/xr';
import globalStore from '@/stores/global';
import useGeolocation from '@/hooks/useGeolocation';
import { observer } from 'mobx-react';
import {
  calculateVelocity,
  mapGeoCoordtoLocalVector3,
  isDistanceWithinRange,
} from '@/utils';
import { throttle } from 'lodash';
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils';
import { randomVector } from '@/utils';

export interface HeroProps extends React.PropsWithChildren {
  action?: string;
}

const velocity = new Vector3();
const SPEED = 0.1;

const throttledLog = throttle((...args) => {
  console.log(...args);
}, 5000);

const Hero: React.FC<HeroProps> = ({ action = 'idle' }) => {
  const ref = useRef<Mesh>(null);
  const elapsedTime = useRef(0);
  const origin = useFBX('/assets/hero/walking_stationary.fbx');
  const fbx = useMemo(() => SkeletonUtils.clone(origin), []);
  const clapAnimation = useFBX('/assets/hero/animations/clapping.fbx');
  const idleAnimation = useFBX('/assets/hero/animations/idle.fbx');
  const danceAnimation = useFBX('/assets/hero/animations/dance.fbx');

  if (fbx.animations.length < 4) {
    fbx.animations.push(
      origin.animations[0],
      clapAnimation.animations[0],
      idleAnimation.animations[0],
      danceAnimation.animations[0],
    );

    fbx.animations[0].name = 'walking';
    clapAnimation.animations[0].name = 'clap';
    idleAnimation.animations[0].name = 'idle';
    danceAnimation.animations[0].name = 'dance';
  }

  useLayoutEffect(() => {
    fbx.traverse((obj) => {
      obj.castShadow = true;
    });
  }, []);

  const { mixer, names, actions, clips } = useAnimations(fbx.animations, fbx);

  const { isPresenting, session } = useXR();

  const { geolocationPosition } = useGeolocation();

  const [isLocationInited, setIsLocationInited] = useState(false);

  const recalculateVelocity = (
    currentVelocity: Vector3,
    expectVelocity: Vector3,
  ) => {
    currentVelocity.lerp(expectVelocity, 0.85);
  };

  useEffect(() => {
    if (geolocationPosition?.coords) {
      setIsLocationInited(true);
    }
  }, [geolocationPosition?.coords]);

  const getDestination: () => Vector3 | undefined = () => {
    if (!geolocationPosition?.coords) return;
    return mapGeoCoordtoLocalVector3(geolocationPosition?.coords);
  };

  const defaultPosition = useMemo(
    () => (!isPresenting && getDestination()) || new Vector3(),
    [isLocationInited],
  );

  const pseudoVector = new Vector3(
    Math.random() * 2 - 1,
    0,
    Math.random() * 2 - 1,
  );

  setTimeout(() => {
    pseudoVector.copy(
      new Vector3(Math.random() * 2 - 1, 0, Math.random() * 2 - 1),
    );
  }, 1000);

  useFrame((_state, delta) => {
    if (isPresenting) return;
    if (!ref.current) return;

    // const destination = getDestination() ?? new Vector3();
    // const { distance, velocity: destinationVelocity } = calculateVelocity(
    //   destination,
    //   ref.current.position,
    // );
    // const isInRange = isDistanceWithinRange(distance);
    // const shouldAutoMove = !isInRange && elapsedTime.current > 1;

    elapsedTime.current > 9
      ? (elapsedTime.current = 0)
      : (elapsedTime.current += delta);

    // if (shouldAutoMove) {
    //   recalculateVelocity(velocity, destinationVelocity);
    // } else {
    // }

    if (elapsedTime.current > 3) {
      velocity.copy(pseudoVector);

      ref.current.lookAt(
        ref.current.position.clone().addScaledVector(velocity, -SPEED * delta),
      );
    } else {
      velocity.copy(new Vector3());
    }

    if (velocity.length()) {
      actions?.[action]?.stop();
      actions?.['walking']?.play();
    } else {
      actions?.['walking']?.stop();
      actions?.[action]?.play();
    }

    // console.log(`~~~~~~~~~~~`, ref.current.position)
    ref.current.position.addScaledVector(velocity, SPEED * delta);
  });

  return (
    <mesh
      name="hero"
      ref={ref}
      receiveShadow
      castShadow
      position={defaultPosition.add(
        new Vector3(...[Math.random() * 3 - 1.5, 0, Math.random() * 3 - 1.5]),
      )}
    >
      <primitive
        object={fbx}
        scale={0.06}
        position={[0, -0.85, 0]}
        rotation={[0, Math.PI, 0]}
        dispose={null}
      />
    </mesh>
  );
};

Hero.displayName = 'Components.OtherHero';
export default observer(Hero);
