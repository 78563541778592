import { useRequest } from 'ahooks-v2';

const Profile = () => {
  const { data, error, loading } = useRequest({
    url: '/api/my/selected-color',
    method: 'get',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('_authing_token')}`,
    },
  });

  if (error) {
    return <div>Failed to load</div>;
  }

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <div>
      {/*<h1>My Profile</h1>*/}
      {/*<p>Selected Color: {JSON.stringify(data.data.value)}</p>*/}
      <iframe
        title="profile"
        src="https://hardway.authing.cn/u?app_id=6389cf494b2e2b25f818ab6d&user_active_tab=basic"
        style={{ width: '100%', height: '100vh', border: 'none', margin: 0 }}
      ></iframe>
    </div>
  );
};

export default Profile;
