import React, { useState, useEffect, useCallback } from 'react';
import { throttle } from 'lodash';
import { getCoordinate, reportCoordinate } from '@/apis/coordinate';
import Expanse from '@/assets/count-steps-expanse.svg';
import useGeolocation from '@/hooks/useGeolocation';
import cls from 'classnames';
import styles from './index.module.scss';
import { useGuard, User } from '@authing/guard-react18';

// export { default as CountStepsDev } from './dev';

export interface Props {
  className?: string;
  style?: React.CSSProperties;
}

const MOCK_AVATAR = 'https://assets.srm.lego.cn/IMG_cc46eaf959.png';
const MOCK_NICKNAME = 'Astronaut';

const uploadCoordinate = (() => {
  const debounced = throttle(reportCoordinate, 3000);
  let isAvailable = true;

  return (...args: Parameters<typeof debounced>) => {
    if (!isAvailable) return;

    isAvailable = false;

    return debounced(...args)?.finally(() => {
      isAvailable = true;
    });
  };
})();

const CountSteps: React.FC<Props> = (props) => {
  const { className, style } = props;
  const [steps, setSteps] = useState<number>();
  const [ranking, setRanking] = useState<number>();
  const { geolocationPosition } = useGeolocation();
  const [userInfo, setUserInfo] = useState<User | null>(null);
  const [isWalking, setIsWalking] = useState(false);
  const guard = useGuard();

  useEffect(() => {
    if (!geolocationPosition) {
      return;
    }

    setIsWalking(true);
    setTimeout(() => {
      setIsWalking(false);
    }, 3000);

    uploadCoordinate(geolocationPosition.coords)?.then(({ data }) => {
      const { footStep, ranking } = data.data;
      setSteps(footStep);
      setRanking(ranking);
    });
  }, [geolocationPosition]);

  useEffect(() => {
    guard.trackSession().then((res: User | null) => {
      setUserInfo(res);
    });
  }, []);

  useEffect(() => {
    getCoordinate().then((res) => {
      console.log('coor = ', res);
      setSteps(res.data.data.footStep);
      setRanking(res.data.data.ranking);
    });
  }, []);

  return (
    <div className={cls(styles.countSteps, className)} style={style}>
      <a
        className={cls(styles.avatar, isWalking && styles.breathing)}
        href={userInfo ? '/profile' : '/'}
        target={userInfo ? '_blank' : '_self'}
      >
        <img src={userInfo?.photo ?? MOCK_AVATAR} alt="avatar" />
      </a>
      <div className={styles.content}>
        <div className={styles.nickname}>
          {userInfo?.nickname ??
            userInfo?.username ??
            userInfo?.name ??
            userInfo?.email ??
            MOCK_NICKNAME}
        </div>
        <div className={styles.steps}>
          <div className={styles.label}>今日步数</div>
          <div className={styles.value}>{steps ?? 0}</div>
        </div>
        <div className={styles.ranking}>
          <div className={styles.label}>今日排名</div>
          <div className={styles.value}>{ranking ?? 1}</div>
          <div className={styles.ordinalSuffix}></div>
        </div>
      </div>
      <div className={styles.expanse}>
        <img src={Expanse} />
      </div>
    </div>
  );
};

export default CountSteps;
