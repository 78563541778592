import { useRequest } from 'ahooks-v2';
import React from 'react';
import { Avatar, Card, List } from 'antd';

const Friends = () => {
  const { data, error, loading } = useRequest({
    url: '/api/friends',
    method: 'get',
  });

  if (error) {
    return <div>Failed to load</div>;
  }

  if (loading) {
    return <div>loading...</div>;
  }

  const {
    data: {
      data: { list, totalCount },
    },
  } = data;

  return (
    <div>
      <h1>Total Friends: {totalCount}</h1>
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={list}
        renderItem={(item: any) => (
          <List.Item key={item.userId}>
            <Card
              hoverable
              style={{ width: 240 }}
              key={item.userId}
              cover={<img alt={item.email} src={item.photo} />}
            >
              <Card.Meta
                avatar={<Avatar src={item.photo} />}
                title={item.nickname || item.username}
                description={item.email}
              />
              <Card.Meta
                title={`Logged in ${item.loginsCount} times`}
                description={`Registered at ${item.createdAt}`}
              />
              <Card.Meta description={`Last login at ${item.lastLogin}`} />
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};

export default Friends;
