import {
  ApolloClient,
  ApolloLink,
  concat,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { setContext } from '@apollo/client/link/context';

import packageJson from '../../package.json';
import { sha256 } from 'crypto-hash';

const apolloCloudGateway: string =
  'https://current--brickverse.apollographos.net/graphql';
const apolloSelfHostedGateway: string = '/api/graphql-gateway/self-hosted';
const brickverseGraphQLGateway = 'https://graph-gateway.azurewebsites.net';

const theFetch = async (url, options) =>
  await fetch(url.toString(), {
    method: options?.method || 'POST',
    headers: {
      'content-type': 'application/json',
      'x-graphql-client-name': 'brickverse-web',
      'x-graphql-client-version': packageJson.version,
      ...options.headers,
    },
    body: options?.body,
  });

const httpLink = createHttpLink({
  uri: apolloCloudGateway,
  fetch: theFetch,
});

const httpLinkForSelfHostedGateway = createHttpLink({
  uri: apolloSelfHostedGateway,
  fetch: theFetch,
});

const httpLinkForBrickverseGraphQLGateway = createHttpLink({
  uri: brickverseGraphQLGateway,
  fetch: theFetch,
});

const queryLink = createPersistedQueryLink({
  sha256,
  useGETForHashedQueries: true,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('_authing_token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : undefined,
    },
  };
});

const httpLinkForNormalOperations = ApolloLink.from([queryLink, httpLink]);

export const client = new ApolloClient({
  link: concat(authLink, httpLinkForNormalOperations),
  cache: new InMemoryCache(),
});

export const noCacheClient = new ApolloClient({
  link: concat(authLink, httpLink),
  cache: new InMemoryCache(),
});

export const selfHostedClient = new ApolloClient({
  link: concat(authLink, httpLinkForSelfHostedGateway),
  cache: new InMemoryCache(),
});

export const brickverseClient = new ApolloClient({
  link: concat(authLink, httpLinkForBrickverseGraphQLGateway),
  cache: new InMemoryCache(),
});
