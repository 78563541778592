import React, { useRef } from 'react';
import { useHitTest } from '@react-three/xr';
import { PrimitiveProps, useFrame } from '@react-three/fiber';
import { Instance, Sphere, useGLTF } from '@react-three/drei';
import { Mesh, Vector3 } from 'three';

interface IReticle {
  reticleRef?: React.MutableRefObject<Mesh>;
}

const Reticle: React.FC<IReticle> = () => {
  const ref = useRef<PrimitiveProps>(null);
  const gltf = useGLTF('/assets/reticle/reticle.gltf');

  useHitTest((hitMatrix: THREE.Matrix4, hit: XRHitTestResult) => {
    if (!ref.current) return;

    // use hitMatrix to position any object on the real life surface
    hitMatrix.decompose(
      ref.current.position,
      ref.current.quaternion,
      ref.current.scale,
    );
  });

  return <primitive ref={ref} object={gltf.scene} position={[0, -0.9, 0]} />;
};

useGLTF.preload('/assets/reticle/reticle.gltf');
export default Reticle;
