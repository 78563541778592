import React, { useState, useEffect, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { Group } from 'three';
import { useNavigate } from 'react-router';
import ARIcon from '@/components/icons/ar';
import EmojiIcon from '@/components/icons/emoji';
import './index.css';
interface IOverlay {
  isPresenting?: boolean;
  groupRef: React.RefObject<Group>;

  selectedColor?: string;
}

interface IEmoteIcon {
  thumbnail?: string;
  action?: string;
  onClick?: () => void;
}
const EmoteIcon: React.FC<IEmoteIcon> = ({
  thumbnail = '',
  action = 'hello',
  onClick,
}) => {
  return (
    <div
      style={{
        borderRadius: '5px',
        height: 75,
        width: 75,
        backgroundColor: '#f6f6f6',
      }}
    >
      <img src={thumbnail} />
      <p>{action}</p>
    </div>
  );
};

const Overlay: React.FC<IOverlay> = ({
  groupRef,
  isPresenting = false,
  selectedColor = '#FFD400',
}) => {
  const [open, setModal] = useState(false);
  const [isARReady, setARReady] = useState(false);
  const navigate = useNavigate();
  useLayoutEffect(() => {
    navigator.xr?.isSessionSupported('immersive-ar').then((supported) => {
      if (!supported) {
        return;
      }
      // 'immersive-ar' sessions are supported.
      // Page should advertise AR support to the user.
      setARReady(true);
    });
  }, []);

  const handleActivate = async () => {
    const btn = document.getElementById('ar-button');

    if (isPresenting) {
      // const _canvas = document.getElementsByTagName("canvas").item(0);
      // const _container = document.querySelector(".canvas-container div");
      // const _canvasCopy = _canvas.cloneNode(true);
      // console.log("_container", _container, _canvasCopy);
      // const session = window.gl.xr.getSession();
      // await session.end();

      // _container.appendChild(_canvasCopy);
      navigate(0);
    }

    btn?.click();
  };
  const handleClick = () => {
    if (!groupRef?.current) return;
    setModal(!open);
    const hero = groupRef.current.getObjectByName('hero');
    console.log('hi', hero);
  };

  const emotes = [
    {
      thumbnail: '',
      name: 'hello',
    },
    {
      thumbnail: '',
      name: 'hello',
    },
    {
      thumbnail: '',
      name: 'hello',
    },
    {
      thumbnail: '',
      name: 'hello',
    },
    {
      thumbnail: '',
      name: 'hello',
    },
  ];

  return ReactDOM.createPortal(
    <>
      <div className="icon ar-overlay">
        {isARReady && (
          <div className="emotes-btn" onClick={handleActivate}>
            <ARIcon color={isPresenting ? '#FFD400' : '#00395D'} />
          </div>
        )}
        <div className="icon emotes-btn" onClick={handleClick}>
          <EmojiIcon color={open ? selectedColor : '#00395D'} />
        </div>
      </div>
      <div>
        <div
          className="grid"
          style={{ bottom: open ? 0 : -600, background: 'white' }}
        >
          {emotes.map((item, index) => (
            <EmoteIcon key={`overlay-item-${index}`} {...item} />
          ))}
        </div>
      </div>
    </>,
    document.getElementById('overlay')!,
  );
};

export default Overlay;
