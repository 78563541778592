import * as util from 'util';
import { DocumentNode } from '@apollo/client';
import { Button } from 'antd';

export const ErrorDisplay = ({
  error,
  children,
  refetch,
  query,
}: {
  error: object;
  children: any;
  refetch?: any;
  query?: DocumentNode;
}) => {
  console.log('refetch = ', refetch);

  return (
    <div className="at-article">
      <h1 className="at-article__h1">对不起！发生了错误……</h1>
      <p className="at-article__p">
        有可能内容被删除了，如果重试无效，请返回查看其他内容。
      </p>
      <p className="at-article__p">{util.inspect(error)}</p>
      <div className="at-article__content">
        {refetch && <Button onClick={refetch}>重试</Button>}
        <p>{children}</p>
        <p>涉及到的查询是：</p>
        <p>{JSON.stringify(query)}</p>
      </div>
    </div>
  );
};
