import { useState, useEffect } from 'react';

export default () => {
  const [geolocationPosition, setGeolocationPosition] =
    useState<GeolocationPosition>();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(setGeolocationPosition);

    const watchId = navigator.geolocation.watchPosition((position) => {
      setGeolocationPosition(position);
    });

    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  return {
    geolocationPosition,
  };
};
