import React from 'react';
import withGraphqlQuery from '@/components/higher-orders/with-graphql-query';
import { gql } from '@apollo/client';
import { brickverseClient } from '@/graphql/apollo-client';

const Display = ({ data }) => {
  return (
    <div>
      <h1>Testing</h1>
      <h2>{data?.post?.data?.attributes?.Title}</h2>
      <p>{data?.post?.data?.attrigutes?.Content}</p>
    </div>
  );
};
const TestComponent = withGraphqlQuery(
  Display,
  gql`
    query GetPost($id: ID!) {
      post(id: $id) {
        data {
          id
          attributes {
            Title
            Content
          }
        }
      }
    }
  `,
  {
    client: brickverseClient,
    variables: {
      id: 1,
    },
  },
);

const Contributors = withGraphqlQuery(
  ({ data }) => {
    return (
      <div>
        {data?.brickverseTeams?.map((node) => {
          return (
            <div key={node.id}>
              <h2>{node.name}</h2>
              <p>{node.description}</p>
            </div>
          );
        })}
      </div>
    );
  },
  gql`
    query Teams {
        brickverseTeams {
        id
        name
        description
      }
    }
  `,
  {
    client: brickverseClient,
  },
);

const TeamsPage: React.FC = () => {
  return (
    <div>
      <h1>Makers Page</h1>
      <TestComponent />
      <Contributors />
    </div>
  );
};

export default TeamsPage;
