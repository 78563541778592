import { Outlet } from 'react-router-dom';
import LoginGuard from '@/components/login/loginGuard';
import { Layout, Menu } from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  HomeOutlined,
  ProfileOutlined,
  UsergroupAddOutlined,
  LoginOutlined,
  TeamOutlined,
  SignatureFilled,
} from '@ant-design/icons';
import React, { useState } from 'react';

export default function SiteLayout() {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <LoginGuard>
      <Layout>
        <Layout.Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="logo" />
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[window.location.pathname]}
            items={[
              {
                key: '/home',
                label: <a href="/home">Home</a>,
                icon: <HomeOutlined />,
              },
              {
                key: '/feed',
                label: <a href="/feed">Feed</a>,
                icon: <SignatureFilled />,
              },
              {
                key: '/login',
                label: <a href="/login">Login</a>,
                icon: <LoginOutlined />,
              },
              {
                key: '/profile',
                label: <a href="/profile">Profile</a>,
                icon: <ProfileOutlined />,
              },
              {
                key: '/friends',
                label: <a href="/friends">Friends</a>,
                icon: <UsergroupAddOutlined />,
              },
              {
                key: '/makers',
                label: <a href="/makers">Makers</a>,
                icon: <UsergroupAddOutlined />,
              },
              {
                key: '/teams',
                label: <a href="/teams">Teams</a>,
                icon: <TeamOutlined />,
              },
              {
                key: 'toggle',
                label: (
                  <span onClick={() => setCollapsed(!collapsed)}>Toggle</span>
                ),
                icon: collapsed ? (
                  <MenuUnfoldOutlined
                    onClick={() => setCollapsed(!collapsed)}
                  />
                ) : (
                  <MenuFoldOutlined onClick={() => setCollapsed(!collapsed)} />
                ),
              },
            ]}
          />
        </Layout.Sider>
        <Layout className="site-layout">
          {/*<Layout.Header style={{ padding: 0, background: '#fff' }}>*/}
          {/*  {React.createElement(*/}
          {/*    collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,*/}
          {/*    {*/}
          {/*      className: 'trigger',*/}
          {/*      onClick: () => setCollapsed(!collapsed),*/}
          {/*    },*/}
          {/*  )}*/}
          {/*</Layout.Header>*/}
          <Layout.Content
            style={{
              // margin: '24px 16px',
              // padding: 24,
              minHeight: 280,
              background: '#fff',
            }}
          >
            <Outlet />
            <div id="authing-guard-container"></div>
          </Layout.Content>
          <Layout.Footer style={{ textAlign: 'center' }}>
            Brickverse ©2023 Created by Brickverse Team
          </Layout.Footer>
        </Layout>
      </Layout>
    </LoginGuard>
  );
}
