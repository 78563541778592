import React, { useRef } from 'react';
import { MeshProps, ThreeEvent, useFrame } from '@react-three/fiber';
import { Instance, Instances, Reflector, useTexture } from '@react-three/drei';
import { useXR } from '@react-three/xr';
import * as THREE from 'three';

const Terrain = ({ number = 25, lineWidth = 0.126, height = 0.5 }) => {
  const normalTexture = useTexture('/assets/textures/Blocks_001_NORM.jpg');
  const colorMap = useTexture('/assets/textures/Blocks_001_COLOR_D.jpg');
  const roughnessMap = useTexture('/assets/textures/Blocks_001_ROUGH.jpg');
  const displacementMap = useTexture('/assets/textures/Blocks_001_DISP.png');
  const aoMap = useTexture('/assets/textures/Blocks_001_OCC.jpg');
  colorMap.wrapS = colorMap.wrapT = THREE.RepeatWrapping;
  normalTexture.wrapS = normalTexture.wrapT = THREE.RepeatWrapping;
  roughnessMap.wrapS = roughnessMap.wrapT = THREE.RepeatWrapping;
  displacementMap.wrapS = displacementMap.wrapT = THREE.RepeatWrapping;
  aoMap.wrapS = aoMap.wrapT = THREE.RepeatWrapping;
  colorMap.repeat.set(2048, 2048);
  normalTexture.repeat.set(2048, 2048);
  roughnessMap.repeat.set(2048, 2048);
  displacementMap.repeat.set(2048, 2048);
  aoMap.repeat.set(2048, 2048);
  const terrainRef = useRef<THREE.Group>(null);
  const { isPresenting } = useXR();
  useFrame(() => {
    if (!terrainRef?.current) return;
    //   terrain.current.position.z += 0.4;
  });

  if (isPresenting) return null;
  return (
    <group ref={terrainRef} position={[0, -0.3, 0]} receiveShadow>
      <Instances position={[0, -0.3, 0]} receiveShadow>
        <mesh position={[0, -0.3, 0]} rotation-x={-Math.PI / 2} receiveShadow>
          <planeGeometry args={[3000, 3000]} />
          <meshStandardMaterial
            map={colorMap}
            normalMap={normalTexture}
            roughnessMap={roughnessMap}
            roughness={0.75}
            displacementMap={displacementMap}
            metalness={0.2}
            bumpScale={1.5}
          />
        </mesh>
        <planeGeometry args={[lineWidth, height]} />
        <meshBasicMaterial color="#999" />
        <gridHelper
          args={[200, 200, '#000', '#000']}
          position={[0, -0.29, 0]}
        />
      </Instances>
    </group>
  );
};

Terrain.displayName = 'Components.Terrain';
export default Terrain;
