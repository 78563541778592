import React from 'react';
import { useGuard } from '@authing/guard-react18';
import Embed from '@/components/login/embed';

const Login = () => {
  const guard = useGuard();

  // 跳转到 Authing 托管页面登录
  const startWithRedirect = () => guard.startWithRedirect();

  return (
    <div>
      <div>
        <button className="authing-button" onClick={startWithRedirect}>
          Login
        </button>
      </div>
    </div>
  );
};

export default Embed;
