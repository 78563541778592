import { useContext, useEffect } from 'react';
import { RefreshContext } from '@/contexts/refresh-context';

const useComponentRefresh = (refetch) => {
  const { register, unregister } = useContext(RefreshContext);

  useEffect(() => {
    console.log('registering ', refetch, ' with ', register);
    register(refetch);
    return () => unregister(refetch);
  }, [refetch]);
};

export default useComponentRefresh;
