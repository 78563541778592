import axios from 'axios';

export const reportCoordinate = async (coordinate: GeolocationCoordinates) => {
  const { latitude, longitude } = coordinate;

  return await axios.post(
    '/api/my/coordinate',
    {
      latitude,
      longitude,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('_authing_token')}`,
      },
    },
  );
};

export const getCoordinate = async () => {
  return await axios.get('/api/my/coordinate', {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('_authing_token')}`,
    },
  });
};
