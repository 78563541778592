import withGraphqlQuery from '@/components/higher-orders/with-graphql-query';
import { gql } from '@apollo/client';
import { Card, List } from 'antd';

import styles from './feed.module.scss';

const Feed = ({ data }) => {
  return (
    <List
      className={styles.feed}
      grid={{ gutter: 16, column: 3 }}
      dataSource={data?.posts?.data?.map((post) => post.attributes)}
      renderItem={(item: { Title; Content; publishedAt }) => (
        <List.Item key={item.publishedAt}>
          <Card title={item.Title}>
            <div dangerouslySetInnerHTML={{ __html: item.Content }}></div>
          </Card>
        </List.Item>
      )}
    />
  );
};

export default withGraphqlQuery(
  Feed,
  gql`
    query ($sort: [String], $publicationState: PublicationState) {
      posts(sort: $sort, publicationState: $publicationState) {
        data {
          attributes {
            Title
            Content
            publishedAt
          }
        }
      }
    }
  `,
  {
    variables: {
      sort: 'publishedAt:desc',
      publicationState: 'LIVE',
    },
  },
);
