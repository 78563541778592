import React from 'react';

const EmojiIcon: React.FC<{ color?: string }> = ({ color = '#00395D' }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="9.97827"
        y="6.5"
        width="1.6087"
        height="8.56522"
        rx="0.804348"
        fill={color}
        stroke={color}
      />
      <rect
        x="21.2827"
        y="6.5"
        width="1.6087"
        height="8.56522"
        rx="0.804348"
        fill={color}
        stroke={color}
      />
      <path
        d="M6 19.9131C11.5738 26.3986 19.7705 27.3252 26 19.9131"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default EmojiIcon;
