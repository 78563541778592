import Login from '@/components/login';
import BrickverseLogo from '@/assets/logo192.png';
import Title from '@/assets/title.svg';
import Benny from '@/assets/benny.png';
import styles from './index.module.css';
import { useEffect, useState } from 'react';

const LoginPage = () => {
  const [isShowBricks, setIsShowBricks] = useState(false);

  useEffect(() => {
    setIsShowBricks(true);
  }, []);

  return (
    <div className={styles.bg}>
      <div className={styles.circle} />
      <img src={BrickverseLogo} alt="image" className={styles.logo} />
      <div className={styles.titleWrapper}>
        <img src={Title} alt="Title" />
        <div style={{ marginTop: '20px' }}>
          <Login />
        </div>
      </div>
      <div className={styles.bricksWrapper}>
        <img
          src={Benny}
          alt="Benny"
          className={`${styles.bricks} ${
            isShowBricks ? styles.slideOutUp : ''
          }`}
        />
      </div>
    </div>
  );
};

export default LoginPage;
