import React from 'react';
import ReactDom from 'react-dom';
import { ARButton as ARButtonBase } from '@react-three/xr';
import './index.css';

const ARButton: React.FC = () => {
  // const { isPresenting } = useXR();
  // const { gl } = useThree();
  // const session = gl.xr.getSession();

  // const handleExit = (event) => {

  // }
  return (
    <ARButtonBase
      id="ar-button"
      sessionInit={{
        domOverlay: { root: document.getElementById('overlay')! },
        optionalFeatures: ['dom-overlay'],
        requiredFeatures: ['viewer', 'local-floor', 'dom-overlay'],
      }}
    />
  );
};

export default ARButton;
