import React, { useMemo, useEffect, useState, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import {
  Environment,
  KeyboardControls,
  KeyboardControlsEntry,
  OrbitControls,
} from '@react-three/drei';
import { Interactive, XR } from '@react-three/xr';
import { observer } from 'mobx-react';
import Terrain from '@/components/terrain';
import BG from '@/components/bg';
import Hero from '@/components/hero';
import OtherHero from '@/components/hero/other';
import Tree from '@/components/model/tree';
import Reticle from '@/components/reticle';
import LEGOStores from '@/components/lego-stores';
import { DirectionalLight, Group, Vector3 } from 'three';
import CountSteps from '@/components/count-steps';
import axios from 'axios';
import ARButton from './ar-button';
import Overlay from '@/components/overlay';
import globalStore from '@/stores/global';
import StoreFrontModel from '@/components/lego-stores/model';
import './index.css';
import { mapGeoCoordtoLocalVector3 } from '@/utils';

const args = {
  activeLook: true,
  autoForward: false,
  constrainVertical: false,
  enabled: true,
  heightCoef: 1,
  heightMax: 1,
  heightMin: 0,
  heightSpeed: false,
  lookVertical: true,
  lookSpeed: 0.005,
  movementSpeed: 100,
  verticalMax: Math.PI,
  verticalMin: 0,
};

enum Controls {
  forward = 'forward',
  left = 'left',
  right = 'right',
  back = 'back',
  jump = 'jump',
}

const map = [
  { name: Controls.forward, keys: ['ArrowUp', 'w', 'W'] },
  { name: Controls.back, keys: ['ArrowDown', 's', 'S'] },
  { name: Controls.left, keys: ['ArrowLeft', 'a', 'A'] },
  { name: Controls.right, keys: ['ArrowRight', 'd', 'D'] },
  { name: Controls.jump, keys: ['SpaceBar', 'j', 'J'] },
];

const { autoRotate, text, shadow, ...config } = {
  text: 'Inter',
  clearcoat: { value: 1, min: 0.1, max: 1 },
  clearcoatRoughness: { value: 0.25, min: 0, max: 1 },
  uRefractPower: { value: 0.35, min: 0, max: 5 },
  uTransparent: { value: 0.25, min: 0, max: 5 },
  uIntensity: { value: 1.3, min: 0.0, max: 5.0 },
  uNoise: { value: 0.03, min: 0, max: 1, step: 0.01 },
  uSat: { value: 1.0, min: 1, max: 1.25, step: 0.01 },
  uColor: '#e26686',
  gColor: '#ff7a7a',
  shadow: '#80446c',
  autoRotate: false,
};

function App() {
  const groupRef = useRef<Group>(null);
  const viewGroupRef = useRef<Group>(null);
  const [color, setColor] = useState('orange');
  const [isPresenting, setPresentation] = useState(false);

  const handleClick = (color: string): void => {
    setColor(color);

    axios.post(
      '/api/my/selected-color',
      { key: 'color', value: color },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('_authing_token')}`,
        },
      },
    );
  };

  const handleReposition = () => {
    if (!groupRef.current) return;

    const hero = groupRef.current.getObjectByName('hero');
    const reticle = viewGroupRef.current?.children[0];

    if (!hero || !reticle) return;

    reticle.matrix.decompose(hero.position, hero.quaternion, hero.scale);
  };

  // useEffect(() => {
  //   axios
  //     .get('/api/my/selected-color', {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem('_authing_token')}`,
  //       },
  //     })
  //     .then((res) => {
  //       setColor(res.data.data.value);
  //     });
  // }, []);

  useEffect(() => {
    axios.get('/api/friends-locations', {}).then((res) => {
      console.log('locations = ', res.data);
    });
  }, []);

  return (
    <>
      <div className="side-panel" style={{ display: 'none' }}>
        <div className="scroll-container">
          <div
            onClick={() => handleClick('red')}
            style={{ backgroundColor: 'red' }}
          ></div>
          <div
            onClick={() => handleClick('orange')}
            style={{ backgroundColor: 'orange' }}
          ></div>
          <div
            onClick={() => handleClick('blue')}
            style={{ backgroundColor: 'blue' }}
          ></div>
          <div
            onClick={() => handleClick('green')}
            style={{ backgroundColor: 'green' }}
          ></div>
          <div
            onClick={() => handleClick('purple')}
            style={{ backgroundColor: 'purple' }}
          ></div>
          <div
            onClick={() => handleClick('white')}
            style={{ backgroundColor: 'white' }}
          ></div>
        </div>
      </div>
      <div className="main">
        <Overlay
          groupRef={groupRef}
          isPresenting={isPresenting}
          selectedColor={color}
        />
        <ARButton />
        <KeyboardControls map={map}>
          <Canvas
            className="canvas-container"
            dpr={[1, 2]}
            shadows
            style={{ position: 'unset' }}
          >
            <XR
              referenceSpace="local-floor"
              onSessionStart={() => setPresentation(true)}
            >
              <Interactive onSelect={handleReposition}>
                <group ref={viewGroupRef}>
                  <Reticle />
                </group>
              </Interactive>
              <group ref={groupRef}>
                <ambientLight
                  position={[-10, 5, -20]}
                  intensity={0.2}
                  color="lightblue"
                />
                <pointLight
                  intensity={0.4}
                  position={[64, 20, 45]}
                  castShadow
                  color="cornsilk"
                />
                <directionalLight
                  intensity={0.2}
                  position={[15, 10, 45]}
                  castShadow
                  color="cornsilk"
                />

                <React.Suspense fallback={null}>
                  <Terrain />
                  <Hero />
                  {Array(5)
                    .fill(0)
                    .map((_, index) => {
                      return (
                        <OtherHero
                          key={index}
                          action={['idle', 'clap', 'dance'][index]}
                        />
                      );
                    })}
                  <Tree />
                  <LEGOStores />
                  <StoreFrontModel />
                </React.Suspense>
              </group>
              <BG />
            </XR>
          </Canvas>
        </KeyboardControls>
      </div>
      <CountSteps
        style={{ position: 'absolute', top: '130px', left: '16px' }}
      />
    </>
  );
}

export default observer(App);
