import React, { MutableRefObject, useRef } from 'react';
import { Mesh, Euler, Vector3 } from 'three';
import {
  Instances,
  Instance,
  OrbitControls,
  Environment,
  useGLTF,
} from '@react-three/drei';
import { useXR } from '@react-three/xr';
import { GroupProps, ObjectMap, useFrame } from '@react-three/fiber';
import { mapGeoCoordtoLocalVector3, randomEuler } from '@/utils';
import Model from './model';

enum StoreType {
  LCS,
  LBR,
}

type TStores = {
  name: string;
  coordinates: Pick<GeolocationCoordinates, 'latitude' & 'longitude'>;
  type: StoreType;
};

const storesData: TStores[] = [
  {
    name: 'LEGO Certified Store - IAPM Mall',
    coordinates: { latitude: 31.2174, longitude: 121.4537 },
    type: StoreType.LCS,
  },
  {
    name: 'LEGO Brand Store - People Square',
    coordinates: { latitude: 31.2297, longitude: 121.4762 },
    type: StoreType.LBR,
  },
];

const data = storesData.map((store) => {
  const rotation = randomEuler();
  return {
    name: store.name,
    position:
      mapGeoCoordtoLocalVector3(store.coordinates as GeolocationCoordinates) ??
      new Vector3(),
    rotation,
    scale: new Vector3(0.075, 0.075, 0.075),
  };
});

const random = Math.random();

const StoreModel = (props) => {
  const { isPresenting } = useXR();
  const storeModelRef = useRef<Mesh>(null);

  useFrame((_state, delta) => {
    if (isPresenting) return;
    if (!storeModelRef.current) return;
    const elaspedTime = _state.clock.getElapsedTime() + random * 10000;
    storeModelRef.current.rotation.set(
      Math.cos(elaspedTime) / 2,
      Math.sin(elaspedTime) / 2,
      Math.cos(elaspedTime) / 2,
    );
  });

  if (isPresenting) return null;
  return (
    <group key={props.name} {...props}>
      <pointLight position={[0, 0, 10]} intensity={0.25} />
      <Instance ref={storeModelRef} />
    </group>
  );
};

const LEGOStores: React.FC = () => {
  const { isPresenting } = useXR();
  const { nodes, materials } = useGLTF(
    '/assets/environment/brick1x2_gltf/ProjectName.gltf',
  );

  if (
    !(
      nodes._ncl1_1_group1_InnerFBXASC045NodeFBXASC045Brick1x2_dot_ioFBXASC0450_NodeFBXASC045Brick1x2_dot_io_Model instanceof
      Mesh
    )
  )
    return null;
  if (isPresenting) return null;

  return (
    <mesh>
      <Instances
        range={storesData.length}
        material={materials._ncl1_1SG}
        geometry={
          nodes[
            '_ncl1_1_group1_InnerFBXASC045NodeFBXASC045Brick1x2_dot_ioFBXASC0450_NodeFBXASC045Brick1x2_dot_io_Model'
          ].geometry
        }
      >
        <group position={[0, 2, 0]} scale={[1, 1, 1]} receiveShadow castShadow>
          {data.map((props, i) => (
            <StoreModel key={i} {...props} />
          ))}
        </group>
      </Instances>
    </mesh>
  );
};

useGLTF.preload('/assets/environment/brick1x2_gltf/ProjectName.gltf');
export default LEGOStores;
