import type { OrbitControls as OrbitControlsImpl } from 'three-stdlib';
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useLayoutEffect,
} from 'react';
import { Vector3, Mesh, Euler } from 'three';
import { useFrame, useThree, Color } from '@react-three/fiber';
import {
  useKeyboardControls,
  useFBX,
  useAnimations,
  OrbitControls,
  DeviceOrientationControls,
} from '@react-three/drei';
import { useXR } from '@react-three/xr';
import globalStore from '@/stores/global';
import useGeolocation from '@/hooks/useGeolocation';
import { observer } from 'mobx-react';
import {
  calculateVelocity,
  mapGeoCoordtoLocalVector3,
  isDistanceWithinRange,
} from '@/utils';
import { throttle } from 'lodash';

export interface HeroProps extends React.PropsWithChildren {}

enum Controls {
  forward = 'forward',
  left = 'left',
  right = 'right',
  back = 'back',
  jump = 'jump',
}

const velocity = new Vector3();
const SPEED = 0.07;

const throttledLog = throttle((...args) => {
  console.log(...args);
}, 5000);

const Hero: React.FC<HeroProps> = () => {
  const ref = useRef<Mesh>(null);
  const controlsRef = useRef<OrbitControlsImpl>(null);
  const elapsedTime = useRef(0);
  const fbx = useFBX('/assets/hero/walking_stationary.fbx');
  const { camera } = useThree();
  const clapAnimation = useFBX('/assets/hero/animations/clapping.fbx');
  const idleAnimation = useFBX('/assets/hero/animations/idle.fbx');
  const danceAnimation = useFBX('/assets/hero/animations/dance.fbx');

  if (fbx.animations.length < 4) {
    fbx.animations.push(
      clapAnimation.animations[0],
      idleAnimation.animations[0],
      danceAnimation.animations[0],
    );

    fbx.animations[0].name = 'walking';
    clapAnimation.animations[0].name = 'clap';
    idleAnimation.animations[0].name = 'idle';
    danceAnimation.animations[0].name = 'dance';
  }

  useLayoutEffect(() => {
    fbx.traverse((obj) => {
      obj.castShadow = true;
    });
  }, []);
  const { mixer, names, actions, clips } = useAnimations(fbx.animations, fbx);

  const { isPresenting, session } = useXR();

  const { geolocationPosition } = useGeolocation();

  const [isLocationInited, setIsLocationInited] = useState(false);

  const [sub, get] = useKeyboardControls<Controls>();

  const recalculateVelocity = (
    currentVelocity: Vector3,
    expectVelocity: Vector3,
  ) => {
    currentVelocity.lerp(expectVelocity, 0.85);
  };

  useEffect(() => {
    if (geolocationPosition?.coords) {
      setIsLocationInited(true);
    }
  }, [geolocationPosition?.coords]);

  const initKeyboardController = () => {
    const keyboard = get();

    if (keyboard.left && !keyboard.right) velocity.x = -1;
    if (keyboard.right && !keyboard.left) velocity.x = 1;
    if (!keyboard.left && !keyboard.right) velocity.x = 0;

    if (keyboard.forward && !keyboard.back) velocity.z = -1;
    if (keyboard.back && !keyboard.forward) velocity.z = 1;
    if (!keyboard.forward && !keyboard.back) velocity.z = 0;
  };

  const getDestination: () => Vector3 | undefined = () => {
    if (!geolocationPosition?.coords) return;
    return mapGeoCoordtoLocalVector3(geolocationPosition?.coords);
  };

  const defaultPosition = useMemo(
    () => (!isPresenting && getDestination()) || new Vector3(),
    [isLocationInited],
  );

  useFrame((_state, delta) => {
    if (isPresenting) return;
    if (!ref.current) return;
    if (!controlsRef.current) return;

    initKeyboardController();

    const rotationyxz = camera.rotation.reorder('YXZ');
    const rotationy = new Euler(0, rotationyxz.y, 0, 'XYZ');
    const destination = getDestination() ?? velocity;
    const { distance, velocity: destinationVelocity } = calculateVelocity(
      destination,
      ref.current.position,
    );
    const isInRange = isDistanceWithinRange(distance);
    const shouldAutoMove = !isInRange && elapsedTime.current > 0.5;

    // throttledLog(
    //   `~~~~~~~`,
    //   geolocationPosition?.coords,
    //   destination,
    //   ref.current.position,
    //   {
    //     distance,
    //     isInRange,
    //     shouldAutoMove,
    //   },
    // );

    isInRange ? (elapsedTime.current = 0) : (elapsedTime.current += delta);

    if (shouldAutoMove) {
      recalculateVelocity(velocity, destinationVelocity);
      const from = ref.current.position.clone();
      const to = destination.clone();
      const angle = Math.atan2(to.x - from.x, to.z - from.z) + Math.PI;
      rotationy.y = angle;
    } else {
      velocity.applyEuler(rotationy);
    }

    if (velocity.length()) {
      actions?.['idle']?.stop();
      actions?.['walking']?.play();

      camera.position.addScaledVector(velocity, SPEED * delta);
    } else {
      actions?.['walking']?.stop();
      actions?.['idle']?.play();
    }

    ref.current.position.addScaledVector(velocity, SPEED * delta);
    ref.current.setRotationFromEuler(rotationy);

    controlsRef.current.target.copy(
      ref.current.position.clone().sub(new Vector3(0, 0.5, 0)),
    );
  });

  return (
    <mesh
      name="hero"
      ref={ref}
      receiveShadow
      castShadow
      position={defaultPosition}
    >
      <OrbitControls
        ref={controlsRef}
        enablePan={false}
        minPolarAngle={Math.PI / 5}
        maxPolarAngle={Math.PI / 2}
        minDistance={0.2}
        maxDistance={1}
      />
      <primitive
        object={fbx}
        scale={0.06}
        position={[0, -0.85, 0]}
        rotation={[0, Math.PI, 0]}
      />
    </mesh>
  );
};

Hero.displayName = 'Components.Hero';
export default observer(Hero);
