import React, { Suspense } from 'react';
import { Instances, Instance } from '@react-three/drei';
import { useGLTF, useFBX } from '@react-three/drei';
import { Vector3 } from 'three';
import { mapGeoCoordtoLocalVector3 } from '@/utils';
interface IModel {
  position: Vector3;
}

enum StoreType {
  LCS,
  LBR,
}

type TStores = {
  name: string;
  coordinates: Pick<GeolocationCoordinates, 'latitude' & 'longitude'>;
  type: StoreType;
};

const storesData: TStores[] = [
  {
    name: 'LEGO Certified Store - IAPM Mall',
    coordinates: { latitude: 31.2174, longitude: 121.4537 },
    type: StoreType.LCS,
  },
  {
    name: 'LEGO Brand Store - People Square',
    coordinates: { latitude: 31.2297, longitude: 121.4762 },
    type: StoreType.LBR,
  },
];

const Model: React.FC = () => {
  const fbx = useFBX('/assets/environment/store_gltf/ProjectName.fbx');
  // console.log("fbx", fbx)
  const pos = mapGeoCoordtoLocalVector3({
    latitude: 31.2174,
    longitude: 121.4537,
  } as GeolocationCoordinates);
  return (
    <Suspense fallback={null}>
      <mesh
        position={new Vector3(pos?.x, -1, pos?.z)}
        name="store"
        scale={[0.012, 0.012, 0.012]}
        receiveShadow
        castShadow
      >
        <primitive object={fbx} />
      </mesh>
    </Suspense>
  );
};

export default Model;
