import { Routes, Route } from 'react-router-dom';
import Layout from '@/layouts/index';
import Home from '@/pages/home';
import LoginPage from '@/pages/login';
import { useLogin } from '@/utils/useLogin';
import Profile from '@/pages/profile';
import Friends from '@/pages/friends';
import SiteLayout from '@/layouts/Site';
import { AuthClientProvider } from '@authing/react18-ui-components';
import '@authing/react18-ui-components/lib/index.min.css';
import { AuthenticationClient } from 'authing-js-sdk';
import { AUTHING_APP_ID } from '@/common/constants';
import React from 'react';
import Makers from '@/pages/makers';
import Teams from '@/pages/teams';

import { ApolloProvider } from '@apollo/client';
import { client } from '@/graphql/apollo-client';
import Feed from '@/components/feed/Feed';

const authClient = new AuthenticationClient({
  appId: AUTHING_APP_ID,
});

function App() {
  const isLogin = useLogin(authClient);

  return (
    <ApolloProvider client={client}>
      <AuthClientProvider client={authClient}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={isLogin ? <Home /> : <LoginPage />} />
            <Route path={'/home'} element={<Home />} />
            <Route path={'/login'} element={<LoginPage />} />
          </Route>
          <Route path="/feed" element={<SiteLayout />}>
            <Route index element={<Feed />} />
          </Route>
          <Route path="/profile" element={<SiteLayout />}>
            <Route index element={<Profile />} />
          </Route>
          <Route path="/friends" element={<SiteLayout />}>
            <Route index element={<Friends />} />
          </Route>
          <Route path="/makers" element={<SiteLayout />}>
            <Route index element={<Makers />} />
          </Route>
          <Route path="/teams" element={<SiteLayout />}>
            <Route index element={<Teams />} />
          </Route>
        </Routes>
      </AuthClientProvider>
    </ApolloProvider>
  );
}

export default App;
