import { DocumentNode, gql, QueryHookOptions, useQuery } from '@apollo/client';
import useComponentRefresh from '@/hooks/use-component-refresh';
import { ErrorDisplay } from '@/components/errors/ErrorDisplay';
import React from 'react';
import ErrorBoundary from '@/components/errors/error-boundary';

interface WithGraphqlQueryProps<TData> {
  data?: TData | null | undefined;
  action?: any;
  extra?: any;
}

interface HOCProps<TData> extends WithGraphqlQueryProps<TData> {
  LoadingComponent?: React.ComponentType;
  ErrorComponent?: React.ComponentType<{
    error: Error;
    refetch: () => void;
    query?;
  }>;
  graphqlQuery?: DocumentNode;
  graphqlQueryOptions?: QueryHookOptions;
}

const DefaultLoadingComponent: React.FC = () => <div>正在加载中……</div>;
const DefaultErrorComponent: React.FC<{
  error: Error;
  refetch: () => void;
  query: DocumentNode;
}> = ({ error, refetch, query }) => (
  <ErrorDisplay error={error} refetch={refetch} query={query}>
    发生了错误！
  </ErrorDisplay>
);

/**
 * A higher order component that wraps a component with a graphql query.
 * The component to wrap, all it needs to do is handle the data prop. error and such are handled by this HOC.
 * @param WrappedComponent
 * @param query
 * @param queryOptions
 */
const withGraphqlQuery = <TData extends any = any>(
  WrappedComponent: React.ComponentType<WithGraphqlQueryProps<TData>>,
  query?: DocumentNode,
  queryOptions?: QueryHookOptions,
) => {
  const HOC: React.FC<HOCProps<TData>> = ({
    LoadingComponent,
    ErrorComponent,
    graphqlQuery,
    graphqlQueryOptions,
    data, // This is the data that is passed to the component, if it is already available.
    action,
    extra,
  }) => {
    const finalQuery =
      graphqlQuery ??
      query ??
      gql`
        {
          __type
        }
      `;
    const finalOptions = graphqlQueryOptions ?? queryOptions;

    const {
      loading,
      data: fetchedData,
      error,
      refetch,
    } = useQuery<TData>(finalQuery, finalOptions);

    useComponentRefresh(refetch);

    if (error) {
      const ErrorComponentToRender = ErrorComponent || DefaultErrorComponent;

      return (
        <ErrorComponentToRender
          error={error}
          refetch={refetch}
          query={finalQuery}
        />
      );
    }

    if (loading || !fetchedData) {
      const LoadingComponentToRender =
        LoadingComponent || DefaultLoadingComponent;
      return (
        <ErrorBoundary fallback={<LoadingComponentToRender />}>
          <WrappedComponent data={undefined} action={action} />
        </ErrorBoundary>
      );
    }

    if (typeof data !== 'undefined') {
      return <WrappedComponent data={data} action={action} extra={extra} />;
    }

    return (
      <WrappedComponent data={fetchedData} action={action} extra={extra} />
    );
  };

  return HOC;
};

export default withGraphqlQuery;
