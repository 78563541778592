import React, { useLayoutEffect } from 'react';
import * as THREE from 'three';
import {
  Instances,
  Instance,
  OrbitControls,
  Environment,
  useGLTF,
} from '@react-three/drei';
import { GroupProps, ObjectMap } from '@react-three/fiber';
import { useXR } from '@react-three/xr';
import { randomVector } from '@/utils';
import { mapGeoCoordtoLocalVector3 } from '@/utils';

const TREE_COUNT = 300;
const RANGE = 2500;

const data = Array.from({ length: TREE_COUNT }, (r = RANGE) => ({
  position: new THREE.Vector3(randomVector(r)[0], 0, randomVector(r)[2]),
  rotation: new THREE.Euler((-90 * Math.PI) / 180, 0, 0),
}));
const Model = () => {
  const { isPresenting } = useXR();
  const { nodes, materials } = useGLTF(
    '/assets/environment/tree_gltf/ProjectName.gltf',
  );

  if (isPresenting) return null;
  return nodes._ncl1_2_InnerFBXASC045NodeFBXASC045tree_dot_ioFBXASC0450_NodeFBXASC045tree_dot_io_Model instanceof
    THREE.Mesh ? (
    <Instances
      range={TREE_COUNT}
      receiveShadow
      castShadow
      material={materials._ncl1_1SG}
      geometry={
        nodes
          ._ncl1_2_InnerFBXASC045NodeFBXASC045tree_dot_ioFBXASC0450_NodeFBXASC045tree_dot_io_Model
          .geometry
      }
    >
      <group position={[0, -0.9, 0]} scale={[0.1, 0.1, 0.1]}>
        {data.map((props: GroupProps, i) => (
          <group key={i} {...props}>
            <Instance />
          </group>
        ))}
      </group>
    </Instances>
  ) : null;
};

useGLTF.preload('/assets/environment/tree_gltf/ProjectName.gltf');
export default Model;
